import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GradientConfig } from 'src/app/app-config';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public windowWidth: number;
  public gradientConfig: any;
  @Output() public onNavMobCollapse = new EventEmitter();

  constructor() {
    this.gradientConfig = GradientConfig.config;
    this.windowWidth = window.innerWidth;
  }

  public ngOnInit() { }

  public navMobCollapse() {
    if (this.windowWidth < 992) {
      this.onNavMobCollapse.emit();
    }
  }
}
