import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'elbuild-ui-lib-core';
import { first } from 'rxjs/operators';

@Injectable()
export class VersionCheckService {
	private currentVersion = '{{POST_BUILD_ENTERS_HASH_HERE}}';

	constructor(private http: HttpClient,
		private messageService: MessageService) { }

	public initVersionCheck(url: string, frequency = 1000 * 60 * 30) {
		setInterval(() => {
			this.checkVersion(url);
		}, frequency);
	}

	private checkVersion(url: string) {
		// timestamp these requests to invalidate caches
		this.http.get(url + '?t=' + new Date().getTime())
			.pipe(first())
			.subscribe(
				(response: any) => {
					const hash = response.hash;
					const hashChanged = this.hasHashChanged(this.currentVersion, hash);
					// If new version, do something
					if (hashChanged) {
						const warning = this.messageService.sendIndefineteWarn(
							`E' disponibile un nuovo aggiornamento dell'applicazione, clicca qui per aggiornare!`, 'ATTENZIONE');
						warning.onTap.subscribe(() => {
							location.reload(true);
						});
					}
					// store the new hash so we wouldn't trigger versionChange again
					// only necessary in case you did not force refresh
					this.currentVersion = hash;
				},
				(err) => {
					console.error(err, 'Could not get version');
				},
			);
	}

	private hasHashChanged(currentHash: string, newHash: string): boolean {
		if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
			return false;
		}
		return currentHash !== newHash;
	}
}
