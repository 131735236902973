import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthConfig, AuthService, Login, MessageService, SharedDataService, Staff, User } from 'elbuild-ui-lib-core';
import { UserAdmin } from 'matteini-ui-lib';
import { Md5 } from 'ts-md5/dist/md5';
// import { UserService } from 'src/app/@core/data/user.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

	public password: string;
	public confirmpassword: string;
	public loggedUser: UserAdmin;

	constructor(// private userService: UserService,
		private sharedDataService: SharedDataService,
		private translateService: TranslateService,
		private router: Router,
		private messageService: MessageService) {
		this.sharedDataService.currentLoggedUser.subscribe((u) => {
			this.loggedUser = u as unknown as UserAdmin
			if (this.loggedUser /*&& !this.loggedUser.needchangepassword*/) {
				this.router.navigateByUrl('/');
			}
		});
	}

	public ngOnInit() {
	}

	public changepwd() {
		if (this.loggedUser) {
			/*
			this.userService.changePassword(this.loggedUser.id, { password: Md5.hashStr(this.password) }).subscribe(() => {
				this.messageService.sendSuccess(
					this.translateService.instant('labels.change-password-success'),
					this.translateService.instant('labels.password'));
				if (this.loggedUser.mallid) {
					this.sharedDataService.addCommonData('mall', this.loggedUser.mallid);
					this.router.navigateByUrl(`/mall-access/${this.loggedUser.mallid}/dashboard`);
				} else {
					this.router.navigateByUrl('/');
				}
			}, ({ error }) => {
				this.messageService.sendError(error, this.translateService.instant('labels.password'));
			});
			*/
		}
	}

}
