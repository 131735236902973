import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from 'class-transformer';
import { Feature } from '../model/feature.model';
import { CrudService, Search, SortDirection, Page } from 'elbuild-ui-lib-core';
import { Role, RoleFeature } from '../model/role.model';
import { map } from 'rxjs/operators';


@Injectable()
export class RoleService {

    httpOptions: any;

    constructor(protected http: HttpClient,
        private crudservice: CrudService<Feature>) {
    }

    getAvailableSections() {
        const search = new Search('systemfeature');
        search.addSimpleFilter('tag', 'section');
        search.setSortField('slug');
        search.setSortDirection(SortDirection.ASCENDING);
        const page = new Page(1000);
        return this.crudservice.searchEntities(Feature, 'systemfeature', search, page);
    }

    getAvailableFeatures() {
        const search = new Search('systemfeature');
        search.addSimpleFilter('tag', 'ui');
        search.setSortField('slug');
        search.setSortDirection(SortDirection.ASCENDING);
        const page = new Page(1000);
        return this.crudservice.searchEntities(Feature, 'systemfeature', search, page);
    }

    getRole(id: number): Observable<any> {
        return this.http.get(`/ws/role/${id}`)
            .pipe(map(res => plainToClass(Role, res as Object)));
    }

    getRoleFeaturesByRid(rid: number): Observable<any> {
        const search = new Search('roletofeature join systemfeature on roletofeature.fid = systemfeature.id');
        search.addSimpleFilter('systemfeature.tag', 'ui');
        search.addSimpleFilter('rid', rid);
        const page = new Page(1000);
        return this.crudservice.searchEntities(Feature, 'roletofeature', search, page);
    }

    getRoleSectionsByRid(rid: number): Observable<any> {
        const search = new Search('roletofeature join systemfeature on roletofeature.fid = systemfeature.id');
        search.addSimpleFilter('systemfeature.tag', 'section');
        search.addSimpleFilter('rid', rid);
        const page = new Page(1000);
        return this.crudservice.searchEntities(Feature, 'roletofeature', search, page);
    }

    assignFeatureToRole(rid: number, fid: number): Observable<any> {
        return this.http.post(`/ws/roletofeature`, { rid, fid })
            .pipe(map(res => plainToClass(RoleFeature, res as Object)));
    }

    unassignFeatureToRole(id: number): Observable<any> {
        return this.http.delete(`/ws/roletofeature/${id}`);
    }

    assignFeaturesToRole(models: any[]): Observable<any> {
        return this.http.post(`/ws/roletofeature/addall`, classToPlain(models))
            .pipe(map(res => plainToClass(RoleFeature, res as Object)));
    }

    removeFeaturesToRole(models: any[]): Observable<any> {
        return this.http.post(`/ws/roletofeature/removeall`, classToPlain(models))
            .pipe(map(res => plainToClass(RoleFeature, res as Object)));
    }
}
