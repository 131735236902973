<ng-container *ngIf="title?.title">
	<ng-container>
		<div class="page-header">
			<div class="page-block">
				<div class="row align-items-center">
					<div class="col-md-12">
						<div class="page-header-title">
							<h5
								class="m-b-10"
								*ngIf="title.param !== undefined">
								<span
									*ngIf="title.icon && title.icontooltip"
									class="pcoded-micon"
									ngbTooltip="{{title.icontooltip | translate}}">

									<i [ngClass]="title.icon"></i>
								</span>
								{{title.title | translate:title.param}}
								<i
									*ngIf="docinvalid"
									container="body"
									ngbTooltip="{{'labels.docinvalid' | translate}}"
									class="icon-invalid feather icon-alert-octagon"></i>
								<i
									*ngIf="docinvalid === false"
									container="body"
									ngbTooltip="{{'labels.docvalid' | translate}}"
									class="icon-valid feather icon-check-circle"></i>

								<span
									*ngIf="badge"
									class="badge-{{badge.style}} badge mr-1 float-right"
									translate>
									{{badge.text  | translate}}
								</span>
							</h5>

						</div>
						<ul class="breadcrumb">
							<li class="breadcrumb-item">
								<a
									queryParamsHandling="merge"
									[routerLink]="['/dashboard/']"
									*ngIf="type === 'theme2'">
									<i class="feather icon-home"></i>
								</a>
								<a
									queryParamsHandling="merge"
									[routerLink]="['/dashboard/']"
									*ngIf="type === 'theme1'">
									Home
								</a>
							</li>
							<ng-container *ngFor="let breadcrumb of navigationList">
								<li
									class="breadcrumb-item"
									*ngIf="breadcrumb.url !== false">
									<span
										*ngIf="breadcrumb.icon && breadcrumb.icontooltip"
										class="pcoded-micon mr-1"
										ngbTooltip="{{breadcrumb.icontooltip | translate}}">
										<i [ngClass]="breadcrumb.icon"></i>
									</span>
									<a
										*ngIf="breadcrumb.url"
										[fragment]="breadcrumb.fragment"
										queryParamsHandling="merge"
										(click)="onLinkClicked(breadcrumb)"
										[routerLink]="breadcrumb.url">
										{{breadcrumb.title  | translate:breadcrumb.param}}
									</a>
									<a
										*ngIf="!breadcrumb.url"
										href="javascript:">
										{{breadcrumb.title  | translate:breadcrumb.param}}
									</a>

								</li>

							</ng-container>

							<li
								class="breadcrumb-item"
								*ngIf="title && navigationList.length === 0">
								<span
									*ngIf="title.icon && title.icontooltip"
									class="pcoded-micon mr-1"
									ngbTooltip="{{title.icontooltip | translate}}">
									<i [ngClass]="title.icon"></i>
								</span>
								<a
									*ngIf="title.param !== undefined"
									href="javascript:">
									{{title.title | translate:title.param}}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>
