<div class="row text-center">
	<div class="col-md-4">
		<div class="btn-group">
			<div
				class="btn btn-primary"
				mwlCalendarPreviousView
				[view]="view"
				[(viewDate)]="viewDate"
				(viewDateChange)="closeOpenMonthViewDay()">
				{{'labels.previous' | translate}}
			</div>
			<div
				class="btn btn-outline-secondary"
				mwlCalendarToday
				[(viewDate)]="viewDate">
				{{'labels.today' | translate}}
			</div>
			<div
				class="btn btn-primary"
				mwlCalendarNextView
				[view]="view"
				[(viewDate)]="viewDate"
				(viewDateChange)="closeOpenMonthViewDay()">
				{{'labels.nextday' | translate}}
			</div>
		</div>
	</div>
	<div class="col-md-4">
		<h3>
			{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
		</h3>
	</div>
	<div class="col-md-4">
		<div class="btn-group">
			<div
				class="btn btn-primary"
				(click)="setView(CalendarView.Month)"
				[class.active]="view === CalendarView.Month">
				{{'labels.month' | translate}}
			</div>
			<div
				class="btn btn-primary"
				(click)="setView(CalendarView.Week)"
				[class.active]="view === CalendarView.Week">
				{{'labels.week' | translate}}
			</div>
			<div
				class="btn btn-primary"
				(click)="setView(CalendarView.Day)"
				[class.active]="view === CalendarView.Day">
				{{'labels.day' | translate}}
			</div>
		</div>
	</div>
</div>
<br>
<div [ngSwitch]="view">
	<mwl-calendar-month-view
		*ngSwitchCase="CalendarView.Month"
		[viewDate]="viewDate"
		[events]="events"
		[refresh]="refresh"
		[activeDayIsOpen]="activeDayIsOpen"
		(dayClicked)="dayClicked($event.day)"
		(eventClicked)="handleEvent('Clicked', $event.event)"
		(eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-month-view>
	<mwl-calendar-week-view
		*ngSwitchCase="CalendarView.Week"
		[viewDate]="viewDate"
		[events]="events"
		[refresh]="refresh"
		(eventClicked)="handleEvent('Clicked', $event.event)"
		(eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-week-view>
	<mwl-calendar-day-view
		*ngSwitchCase="CalendarView.Day"
		[viewDate]="viewDate"
		[events]="events"
		[refresh]="refresh"
		(eventClicked)="handleEvent('Clicked', $event.event)"
		(eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-day-view>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->

<br>
<br>
<br>

<h3>
	{{'labels.events' | translate}}
	<button
		class="btn btn-primary float-right"
		(click)="addEvent()">
		{{'labels.new-event' | translate}}
	</button>
	<div class="clearfix"></div>
</h3>

<div *ngIf="!loading && !events.length">
	<label>{{"labels.empty-events" | translate}}</label>
</div>
<div
	class="table-responsive"
	*ngIf="!loading && events.length">
	<table class="table table-bordered">
		<thead>
			<tr>
				<th *ngIf="!taskid">{{'labels.task' | translate}}</th>
				<th *ngIf="!userid">{{'labels.collaborator' | translate}}</th>
				<th>{{'labels.type' | translate}}</th>
				<th>{{'labels.startat' | translate}}</th>
				<th>{{'labels.endsat' | translate}}</th>
				<th>{{'labels.actions' | translate}}</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let event of events | slice: (page.pageNumber-1) * page.size : page.pageNumber * page.size; let i = index">
				<td *ngIf="!taskid">
					<app-entity-autocomplete
						(onSelected)="onTaskSelected($event);"
						(onDeleted)="onTaskSelected($event)"
						(ngModelChange)="refresh.next()"
						entityName="task"
						entityField="name"
						[required]="true"
						entityFilterType="like"
						[entitySearch]="search.task"
						[entityClass]="taskClass"
						[name]="'task' + i"
						[(ngModel)]="event.task"
						placeholder>
					</app-entity-autocomplete>
				</td>
				<td *ngIf="!userid">
					<app-entity-autocomplete
						(onSelected)="onUserSelected($event);"
						(onDeleted)="onUserSelected($event)"
						(ngModelChange)="refresh.next()"
						entityName="user"
						entityField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entityFieldFormatter]="userFormatter"
						[entitySearch]="search.user"
						[entityClass]="userClass"
						[name]="'user' + i"
						[(ngModel)]="event.user"
						placeholder>
					</app-entity-autocomplete>
				</td>
				<td>
					<select
						class="form-control"
						[(ngModel)]="event.type"
						[name]="'type' + i">
						<option [value]="undefined"></option>
						<option
							*ngFor="let item of usertasklogTypes"
							[value]="item.itemvalue">
							{{item.itemlabel}}
						</option>
					</select>
				</td>
				<td>
					<app-datepicker
						[showtime]="true"
						[name]="'start' + i"
						[(ngModel)]="event.start"
						(onChange)="refresh.next()">

					</app-datepicker>
				</td>
				<td>
					<app-datepicker
						[showtime]="true"
						[name]="'end' + i"
						[(ngModel)]="event.end"
						(onChange)="refresh.next()">

					</app-datepicker>
					<!-- <input
						class="form-control"
						type="text"
						mwlFlatpickr
						[(ngModel)]="event.end"
						(ngModelChange)="refresh.next()"
						[altInput]="true"
						[convertModelValue]="true"
						[enableTime]="true"
						dateFormat="Y-m-dTH:i"
						altFormat="F j, Y H:i"
						placeholder="Not set"> -->
				</td>
				<td>
					<div class="icon-buttons">
						<a (click)="handleEvent('clicked', event)">
							<i
								container="body"
								ngbTooltip="{{'labels.view' | translate}}"
								class="feather icon-eye"></i>
						</a>
						<a (click)="saveEvent(event)">
							<i
								container="body"
								ngbTooltip="{{'labels.save' | translate}}"
								class="feather icon-check"></i>
						</a>
						<a (click)="deleteEvent(event)">
							<i
								container="body"
								ngbTooltip="{{'labels.delete' | translate}}"
								class="feather icon-trash"></i>
						</a>

					</div>
					<!-- <button
						class="btn btn-danger"
						(click)="deleteEvent(event)">
						Delete
					</button> -->
				</td>
			</tr>

		</tbody>
	</table>
	<div class="row">
		<div class="col-md-2 offset-md-5">
			<ngb-pagination
				class="mt-2"
				[(page)]="page.pageNumber"
				[pageSize]="page.size"
				[collectionSize]="events.length"></ngb-pagination>
		</div>
	</div>
</div>

<ng-template
	#modalContent
	let-close="close">
	<div class="modal-header">
		<h5 class="modal-title">{{modalData.event.title}}</h5>
		<button
			type="button"
			class="close"
			(click)="close()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">

		<div class="row">
			<div class="col-md-3">{{'labels.collaborator' | translate}}</div>
			<div class="col-md-9">
				<a
					(click)="close()"
					[routerLink]="[ '/user/edit/' + modalData.event['user'].id ]">
					{{modalData.event['user']?.fullname}}
				</a>
			</div>
			<div class="col-md-3">{{'labels.task' | translate}}</div>
			<div class="col-md-9">{{modalData.event['task'].name}}</div>
		</div>
		<br>

		<div class="row">
			<div class="col-md-3">{{'labels.startat' | translate}}</div>
			<div class="col-md-9">
				{{modalData.event.start | date: 'dd/MM/yyyy hh:mm'}}
			</div>
			<div class="col-md-3">{{'labels.endsat' | translate}}</div>
			<div class="col-md-9">
				{{modalData.event.end | date: 'dd/MM/yyyy hh:mm'}}
			</div>
			<div class="col-md-3">{{'labels.type' | translate}}</div>
			<div class="col-md-9">
				{{'types.usertasklogtypes.' + modalData.event['type'] | translate}}
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<label>
					{{'labels.activity-description' | translate}}
				</label>
				<textarea
					class="form-control"
					[(ngModel)]="this.modalData.event['note']"
					name="desc"
					rows="3"></textarea>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-outline-secondary"
			(click)="closeSaveModal(); close()">
			OK
		</button>
	</div>
</ng-template>
