import { UserAdmin } from 'matteini-ui-lib';
import { Component, OnInit } from '@angular/core';
import { Login, AuthService, SharedDataService, MessageService, AuthConfig, Staff } from 'elbuild-ui-lib-core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	logindata: Login = new Login();
	message: string;
	loggedUser: UserAdmin;
	email: string;

	showRecoverpwd: boolean = true;

	constructor(private authService: AuthService,
		private sharedDataService: SharedDataService,
		private router: Router,
		private messageService: MessageService) {
		this.sharedDataService.currentLoggedUser.subscribe(u => {
			if (this.loggedUser) {
				this.router.navigateByUrl('/starter');
			}
		});
	}

	ngOnInit() {
		if (this.loggedUser) {
			this.router.navigateByUrl('/starter');
		}
	}

	login() {
		this.authService.login(this.logindata).subscribe(data => {
			this.loggedUser = data as UserAdmin
			if (false) {// (false && this.loggedUser.needchangepassword) {
			} else {
				this.router.navigateByUrl(`/`);
			}
		}, ({ error }) => {
			this.messageService.sendError(error, 'Login');
		});
	}

	resetpwd() {
		this.authService.resetPassword(this.email).subscribe(result => {
			this.messageService.sendSuccess(result.msg, 'Reset password');
		}, ({ error }) => {
			this.messageService.sendError(error, 'Reset password');
		});
	}

}
