import { Component, OnInit, OnDestroy } from '@angular/core';
import { ListItem, MessageService, CrudService, SharedDataService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-list-item-edit',
	templateUrl: './list-item-edit.component.html',
	styleUrls: ['./list-item-edit.component.scss']
})
export class ListItemEditComponent implements OnInit, OnDestroy {

	loading: boolean = true;
	item: ListItem;
	lid: number;
	get title(): string {
		return this.item.id ? 'item-edit' : 'item-create';
	}

	constructor(
		private listService: CrudService<ListItem>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private sharedDataService: SharedDataService,
	) {}
	ngOnDestroy(): void {
		this.sharedDataService.addCommonData('route', undefined);
	}

	ngOnInit() {

	}

	load(id?: number): void {
		this.loading = true;
		if (id) {
			this.listService.getEntity(ListItem, 'selistitem', id).subscribe((item: ListItem) => {
				this.item = item;
				this.loading = false;
			}, ({ error }) => {
				this.messageService.sendError(error, this.translateService.instant('labels.lists'));
				this.item = undefined;
				this.loading = false;
			});
		}
		else {
			this.item = new ListItem();
			this.item.lid = this.lid;
			this.loading = false
		}
	}


	dismiss(): void {
		this.activeModal.dismiss();
	}

	submit(): void {
		if (!this.item.id) {
			this.listService.createEntity(ListItem, 'selistitem', this.item).subscribe((response: ListItem) => {
				this.item = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.item-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.item);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else {
			this.listService.saveEntity(ListItem, 'selistitem', this.item).subscribe((response: ListItem) => {
				this.item = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.item-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.item);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

}
