import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, ListItem, ListService, MessageService, Search } from 'elbuild-ui-lib-core';
import { ProjectEvent, Task, UserAdmin, UserTaskLog } from 'matteini-ui-lib';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-user-task-calendar-modal',
	templateUrl: './user-task-calendar-modal.component.html',
	styleUrls: ['./user-task-calendar-modal.component.scss']
})
export class UserTaskCalendarModalComponent implements OnInit {

	@Input() event: ProjectEvent;


	@Input() userid: number;
	@Input() taskid: number;
	@Input() projectid: number;

	search: {
		user: Search,
		task: Search,
	};
	selected: {
		user: UserAdmin,
		task: Task,
	};
	title: string;
	loading: boolean;
	usertasklogTypes: ListItem[] = [];
	userTaskLogs: UserTaskLog[];
	userClass: ClassType<UserAdmin> = UserAdmin;
	taskClass: ClassType<Task> = Task;
	advancedSearch = new Search('usertasklog');
	refresh: Subject<any> = new Subject();
	constructor(
		private activeModal: NgbActiveModal,
		private crudService: CrudService<UserTaskLog>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private listService: ListService,) {
		this.selected = {
			user: new UserAdmin(),
			task: new Task(),
		};
		this.search = {
			user: new Search('user join projectcollaborator on user.id = projectcollaborator.userid'),
			task: new Search('task'),
		};

	}

	ngOnInit() {
		if (this.projectid) {
			this.search.task.addSimpleFilter('projectid', this.projectid);
			this.search.user.addSimpleFilter('projectcollaborator.projectid', this.projectid);
			this.advancedSearch.addSimpleFilter('projectid', this.projectid);
		} else if (this.userid) {
			this.search = {
				user: new Search('user'),
				task: new Search('task join projectcollaborator on task.projectid = projectcollaborator.projectid join user on user.id = projectcollaborator.userid'),
			};
			this.search.task.addSimpleFilter('projectcollaborator.userid', this.userid);
			this.search.user.addSimpleFilter('id', this.userid);
			this.advancedSearch.addSimpleFilter('userid', this.userid);
		}

		if (this.taskid) {
			this.advancedSearch.addSimpleFilter('taskid', this.taskid);
		}
		this.initItem();
	}


	initItem() {
		this.loadUserTaskLogTypes();
		console.log(this.event)
		console.log(this.projectid, this.userid, this.taskid)

	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	close(): void {

		this.activeModal.close(this.event);
	}

	save() {


		this.close();
	}

	onTaskSelected(task: Task) {

	}

	onUserSelected(user: UserAdmin) {

	}
	userFormatter = (user: UserAdmin) => {
		return user.fullname;
	}
	loadUserTaskLogTypes() {
		const options = {
			listname: 'selist',
			listitemname: 'selistitem',
			numitem: 100
		};
		this.listService.getListItemsBySlug('usertasklog.type', options).subscribe(result => {
			this.usertasklogTypes = result;
		}, error => {
			this.messageService.sendError(error.error.msg);
		});
	}


}