<div
	class="row"
	*ngIf="loading">
	<div class="col text-center">
		<app-loader
			[material]="false"
			[dark]="true"></app-loader>
	</div>
</div>

<form
	*ngIf="user"
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">

	<div
		class="row"
		*ngIf="!loading && user">
		<div class="col-sm-12">
			<div class="row">
				<!-- fname -->
				<div class="form-group col-md-4">
					<label for="cname">{{ 'labels.fname' | translate }}*</label>
					<input
						[(ngModel)]="user.fname"
						#fname="ngModel"
						name="fname"
						required
						type="text"
						class="form-control"
						id="cname"
						placeholder="{{ 'labels.fname' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && fname.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && fname.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<!-- lname -->
				<div class="form-group col-md-4">
					<label for="lname">{{ 'labels.lname' | translate }}*</label>
					<input
						[(ngModel)]="user.lname"
						#lname="ngModel"
						name="lname"
						required
						type="text"
						class="form-control"
						id="lname"
						placeholder="{{ 'labels.lname' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && lname.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && lname.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<!-- mobile -->
				<div class="form-group col-md-4">
					<label for="mobile">{{ 'labels.mobile' | translate }}*</label>
					<input
						[(ngModel)]="user.mobile"
						#mobile="ngModel"
						name="mobile"
						required
						type="text"
						class="form-control"
						id="mobile"
						placeholder="{{ 'labels.mobile' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && mobile.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && mobile.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<!-- email -->
				<div class="form-group col-md-4">
					<label for="email">{{ 'labels.email' | translate }}*</label>
					<input
						email
						[(ngModel)]="user.email"
						#email="ngModel"
						name="email"
						required
						type="email"
						class="form-control"
						id="email"
						placeholder="{{ 'labels.email' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && email.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && email.invalid">
						{{'errors.invalid' | translate}}
					</div>
				</div>
				<!-- landile -->
				<div class="form-group col-md-4">
					<label for="landline">{{ 'labels.landline' | translate }}</label>
					<input
						[(ngModel)]="user.landline"
						#landline="ngModel"
						name="landline"
						class="form-control"
						id="landline"
						placeholder="{{ 'labels.landline' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && landline.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && landline.invalid">
						{{'errors.invalid' | translate}}
					</div>
				</div>
				<!-- authtype -->
				<div
					*ngIf="false"
					class="form-group col-md-4">
					<label for="authtype">{{ 'labels.type' | translate }}*</label>

					<app-list-items-selector
						list="user.type"
						name="authtype"
						[(ngModel)]="user.authtype"
						#authtype="ngModel"
						[inputClass]="{'is-invalid': editForm.submitted && authtype.invalid}">
					</app-list-items-selector>

					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && authtype.invalid">
						{{'errors.invalid' | translate}}
					</div>
				</div>
				<!-- country -->
				<div class="form-group col-md-4">
					<label>{{'labels.country' | translate}}</label>

					<app-entity-autocomplete
						(onSelected)="updateSelects();"
						(onDeleted)="updateSelects()"
						entityName="country"
						entityField="name"
						entityFilterType="like"
						[entitySearch]="search.nazione"
						[entityClass]="nazioneClass"
						name="nazione"
						[(ngModel)]="selected.nazione"
						placeholder>
					</app-entity-autocomplete>
				</div>
				<!-- province -->
				<div class="form-group col-md-4">
					<label>{{'labels.province' | translate}}</label>
					<app-entity-autocomplete
						(onSelected)="updateSelects()"
						(onDeleted)="updateSelects()"
						[disabled]="selected.nazione === undefined || !selected.nazione?.isItalia"
						entityName="province"
						entityField="descrizione"
						entityFilterType="like"
						[entitySearch]="search.provincia"
						name="provincia"
						[(ngModel)]="selected.provincia"
						placeholder>
					</app-entity-autocomplete>
				</div>
				<!-- city -->
				<div class="form-group col-md-4">
					<label>{{'labels.comune' | translate}}</label>
					<app-entity-autocomplete
						[disabled]="selected.nazione === undefined || selected.provincia === undefined"
						entityName="comuni"
						entityField="descrizione"
						entityFilterType="like"
						[entitySearch]="search.comune"
						name="comune"
						[(ngModel)]="selected.comune"
						placeholder>
					</app-entity-autocomplete>
				</div>
				<!-- address -->
				<div class="form-group col-md-4">
					<label>{{'labels.address' | translate}}</label>
					<input
						type="text"
						class="form-control"
						name="address"
						[(ngModel)]="user.address"
						#address="ngModel"
						[ngClass]="{'is-invalid': editForm.submitted && address.invalid}">
				</div>
				<!-- streetno -->
				<div class="form-group col-md-4">
					<label>{{'labels.streetno' | translate}}</label>
					<input
						type="text"
						class="form-control"
						name="streetno"
						[(ngModel)]="user.streetno"
						#streetno="ngModel"
						[ngClass]="{'is-invalid': editForm.submitted && streetno.invalid}">
				</div>
				<!-- zipcode -->
				<div class="form-group col-md-4">
					<label>{{'labels.zipcode' | translate}}</label>
					<input
						type="text"
						class="form-control"
						name="zipcode"
						[(ngModel)]="user.zipcode"
						#zipcode="ngModel"
						[ngClass]="{'is-invalid': editForm.submitted && zipcode.invalid}">
				</div>

			</div>
			<!-- password -->
			<div class="row">
				<div class="form-group col-md-4">
					<label>{{'labels.password' | translate}}*</label>
					<input
						[required]="!user.id"
						type="password"
						class="form-control"
						name="pass"
						[(ngModel)]="password.password"
						#pass="ngModel"
						[ngClass]="{'is-invalid': editForm.submitted && pass.invalid}">
				</div>

				<div class="form-group col-md-4">
					<label>
						{{'labels.repeat-password' | translate}}
					</label>
					<input
						autocomplete="new-password"
						type="password"
						class="form-control"
						name="repeatpass"
						[required]="password.password!==''"
						[(ngModel)]="password.repeatpassword"
						#repeatpass="ngModel"
						[ngClass]="{'is-invalid': editForm.submitted && repeatpass.invalid}">
				</div>
			</div>

		</div>
	</div>
	<div class="modal-footer">
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
		<button
			*ngIf="showcancel"
			type="button"
			class="btn btn-secondary"
			(click)="cancel()">
			{{'labels.cancel' | translate}}
		</button>
	</div>
</form>
