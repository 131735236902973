import {
	Component, OnInit, ChangeDetectionStrategy,
	ViewChild,
	TemplateRef,
	Input,
} from '@angular/core';

import {
	startOfDay,
	endOfDay,
	subDays,
	addDays,
	endOfMonth,
	isSameDay,
	isSameMonth,
	addHours,
} from 'date-fns';

import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
	CalendarEvent,
	CalendarEventAction,
	CalendarEventTimesChangedEvent,
	CalendarView,
} from 'angular-calendar';
import { Project, ProjectEvent, Task, UserAdmin, UserTaskLog, CalendarColors } from 'matteini-ui-lib';
import { CrudService, ListItem, ListOptions, ListService, MessageService, Page, Search } from 'elbuild-ui-lib-core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { cloneDeep } from 'lodash';
import { UserTaskCalendarModalComponent } from './user-task-calendar-modal/user-task-calendar-modal.component';




@Component({
	selector: 'app-user-task-calendar',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './user-task-calendar.component.html',
	styleUrls: ['./user-task-calendar.component.scss']
})
export class UserTaskCalendarComponent implements OnInit {

	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

	@Input() userid: number;
	@Input() taskid: number;
	@Input() projectid: number;

	search: {
		user: Search,
		task: Search,
	};
	selected: {
		user: UserAdmin,
		task: Task,
	};
	userClass: ClassType<UserAdmin> = UserAdmin;
	taskClass: ClassType<Task> = Task;
	advancedSearch = new Search('usertasklog');

	usertasklogTypes: ListItem[] = [];

	loading: boolean;

	view: CalendarView = CalendarView.Month;

	CalendarView = CalendarView;

	viewDate: Date = new Date();

	modalData: {
		action: string;
		event: CalendarEvent;
	};

	actions: CalendarEventAction[] = [
		{
			label: '<i class="fas fa-fw fa-pencil-alt"></i>',
			a11yLabel: 'Edit',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.handleEvent('Edited', event);
			},
		},
		{
			label: '<i class="fas fa-fw fa-trash-alt"></i>',
			a11yLabel: 'Delete',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.events = this.events.filter((iEvent) => iEvent !== event);
				this.handleEvent('Deleted', event);
			},
		},
	];

	refresh: Subject<any> = new Subject();

	userTaskLogs: UserTaskLog[];

	events: ProjectEvent[] = [
		/*{
			start: subDays(startOfDay(new Date()), 1),
			end: addDays(new Date(), 1),
			title: 'A 3 day event',
			color: colors.red,
			actions: this.actions,
			allDay: true,
			resizable: {
				beforeStart: true,
				afterEnd: true,
			},
			draggable: true,
		},
		{
			start: startOfDay(new Date()),
			title: 'An event with no end date',
			color: colors.yellow,
			actions: this.actions,
		},
		{
			start: subDays(endOfMonth(new Date()), 3),
			end: addDays(endOfMonth(new Date()), 3),
			title: 'A long event that spans 2 months',
			color: colors.blue,
			allDay: true,
		},
		{
			start: addHours(startOfDay(new Date()), 2),
			end: addHours(new Date(), 2),
			title: 'A draggable and resizable event',
			color: colors.yellow,
			actions: this.actions,
			resizable: {
				beforeStart: true,
				afterEnd: true,
			},
			draggable: true,
		},*/
	];

	activeDayIsOpen: boolean = true;


	userFormatter = (user: UserAdmin) => {
		return user.fullname;
	}

	constructor(private modal: NgbModal,
		private crudService: CrudService<UserTaskLog>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private modalService: NgbModal,
		private dialogService: DialogService,
		private listService: ListService,
		private httpClient: HttpClient) {
		this.selected = {
			user: new UserAdmin(),
			task: new Task(),
		};
		this.search = {
			user: new Search('user join projectcollaborator on user.id = projectcollaborator.userid'),
			task: new Search('task'),
		};

		this.loadUserTaskLogTypes();
	}

	ngOnInit() {
		if (this.projectid) {
			this.search.task.addSimpleFilter('projectid', this.projectid);
			this.search.user.addSimpleFilter('projectcollaborator.projectid', this.projectid);
			this.advancedSearch.addSimpleFilter('projectid', this.projectid);
			this.loadUserTaskEvents();
		} else if (this.userid) {
			this.search = {
				user: new Search('user'),
				task: new Search('task join projectcollaborator on task.projectid = projectcollaborator.projectid join user on user.id = projectcollaborator.userid'),
			};
			this.search.task.addSimpleFilter('projectcollaborator.userid', this.userid);
			this.search.user.addSimpleFilter('id', this.userid);
			this.advancedSearch.addSimpleFilter('userid', this.userid);
			this.loadUserTaskEvents();
		}

		if (this.taskid) {
			this.advancedSearch.addSimpleFilter('taskid', this.taskid);
			this.loadUserTaskEvents();
		}
	}
	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}
	loadUserTaskEvents() {
		this.loading = true;
		this.advancedSearch.page.size = 5
		this.crudService.searchEntities(UserTaskLog, 'usertasklog', this.advancedSearch, this.advancedSearch.page).subscribe((results: UserTaskLog[]) => {
			this.userTaskLogs = results;
			this.events = results.map(res => {
				const pevent = res.projectEvent;
				if (this.projectid && !this.taskid) {
					pevent.title = res.user?.fullname + ' - ' + res.task?.name;
				} else if (this.taskid) {
					pevent.title = res.user?.fullname;
				} else if (this.userid) {
					pevent.title = res.task?.name;
				}
				return pevent;
			});
			this.loading = false;
			this.refresh.next();

		}, error => {
			console.log(error.error.msg);
		});
	}

	loadUserTaskLogTypes() {
		const options = {
			listname: 'selist',
			listitemname: 'selistitem',
			numitem: 100
		};
		this.listService.getListItemsBySlug('usertasklog.type', options).subscribe(result => {
			this.usertasklogTypes = result;
		}, error => {
			this.messageService.sendError(error.error.msg);
		});
	}

	dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		if (isSameMonth(date, this.viewDate)) {
			if (
				(isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
				events.length === 0
			) {
				this.activeDayIsOpen = false;
			} else {
				this.activeDayIsOpen = true;
			}
			this.viewDate = date;
		}
	}

	eventTimesChanged({
		event,
		newStart,
		newEnd,
	}: CalendarEventTimesChangedEvent): void {
		/*this.events = this.events.map((iEvent) => {
			if (iEvent === event) {
				return {
					...event,
					start: newStart,
					end: newEnd,
				};
			}
			return iEvent;
		});
		this.handleEvent('Dropped or resized', event);*/
	}

	handleEvent(action: string, eventsource: CalendarEvent): void {
		const event = cloneDeep(eventsource);
		this.modalData = { event, action };
		this.modal.open(this.modalContent, { size: 'lg' });
	}

	addEvent(): void {
		const modal = this.modalService.open(UserTaskCalendarModalComponent, {
			centered: true,
			size: 'lg',
			backdrop: 'static',
			keyboard: false,
		});
		const event = new ProjectEvent();
		event.init({
			title: 'Nuovo evento',
			start: startOfDay(new Date()),
			end: endOfDay(new Date()),
			color: CalendarColors.red,
			draggable: true,
			resizable: {
				beforeStart: true,
				afterEnd: true,
			},
		});
		event.projectid = this.projectid;
		modal.componentInstance.projectid = this.projectid;
		if (this.taskid) {
			event.taskid = this.taskid;
			modal.componentInstance.taskid = this.taskid;
			event.decorate(this.httpClient);
		}
		if (this.userid) {
			event.userid = this.userid;
			modal.componentInstance.userid = this.userid;
			event.decorate(this.httpClient);
		}

		modal.componentInstance.event = event;

		modal.result.then((item: ProjectEvent) => {
			this.saveEvent(item);
			this.events = [
				...this.events,
				event
			];
		}).catch(() => {});
	}

	deleteEvent(eventToDelete: ProjectEvent) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-logevent-delete'),
			hasIcon: true,
			type: 'danger',
		}).then((result) => {
			if (result) {
				this.events = this.events.filter((event) => event !== eventToDelete);
				if (eventToDelete.id) {
					const usertasklog = eventToDelete.userTaskLog;
					this.crudService.deleteEntity(UserTaskLog, 'usertasklog', usertasklog).subscribe(result => {
						this.messageService.sendSuccess(this.translateService.instant('labels.logevent-deleted'), this.translateService.instant('labels.logevent'));
						this.loadUserTaskEvents();
					}, error => {
						this.messageService.sendError(error.error.msg);
					});
				} else {
					this.refresh.next();
				}
			}
		});


	}

	setView(view: CalendarView) {
		this.view = view;
	}

	closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
	}

	closeSaveModal() {
		const usertasklog = this.userTaskLogs.filter(e => e.id === this.modalData.event.id)[0];
		const projectevent = this.events.filter(e => e.id === this.modalData.event.id)[0];
		if (usertasklog.note !== this.modalData.event['note']) {
			usertasklog.note = this.modalData.event['note'];
			projectevent.note = this.modalData.event['note'];
			this.crudService.saveEntity(UserTaskLog, 'usertasklog', usertasklog).subscribe(result => {
				// this.messageService.sendSuccess(this.translateService.instant('labels.item-saved'), this.translateService.instant('labels.item'));
			}, error => {
				this.messageService.sendError(error.error.msg);
			});
		}
	}

	saveEvent(event: ProjectEvent) {
		if (!event.task) {
			this.messageService.sendError(this.translateService.instant('labels.no-task-error'), this.translateService.instant('labels.event'));
			return;
		}
		if (!event.user) {
			this.messageService.sendError(this.translateService.instant('labels.no-user-error'));
			return;
		}
		const usertasklog = event.userTaskLog;
		const call = (usertasklog.id) ?
			this.crudService.saveEntity(UserTaskLog, 'usertasklog', usertasklog) :
			this.crudService.createEntity(UserTaskLog, 'usertasklog', usertasklog);
		call.subscribe(result => {
			this.messageService.sendSuccess(this.translateService.instant('labels.logevent-saved'), this.translateService.instant('labels.event'));
			this.loadUserTaskEvents();
		}, error => {
			this.messageService.sendError(error.error.msg);
		});
	}

	onTaskSelected(task: Task) {

	}

	onTaskDeleted(task: Task) {

	}

	onUserSelected(user: UserAdmin) {

	}

	onUserDeleted(user: UserAdmin) {

	}

}
