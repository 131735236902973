import { Component, OnInit } from '@angular/core';
import { CrudService, List, Search, MessageService } from 'elbuild-ui-lib-core';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-list-list',
	templateUrl: './list-list.component.html',
	styleUrls: ['./list-list.component.scss']
})
export class ListListComponent implements OnInit {

	rows: List[];
	loading = true;
	advancedSearch: Search = new Search('selist');

	constructor(
		private listService: CrudService<List>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private router: Router
	) { }

	ngOnInit() {
		this.loadItems();
	}

	loadItems() {
		this.loading = true;
		this.rows = [];

		const datarequest = this.listService.searchEntities(List, 'selist', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.listService.countEntities(List, 'selist', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}



	onPageChange(pageInfo: any) {

		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.loadItems();
	}

	rowClass = (row: any) => {
		return '';
	}

	onActivate(event: any) {
		if (true /*this.aclService.hasPermission('centri.detail')*/) {
			if (event.type === 'dblclick') {
				this.router.navigate([`/list/${event.row.id}`], { queryParamsHandling: 'merge' });
			}
		}
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

}
