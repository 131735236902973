<div class="row">

	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					(activate)="onActivate($event)"
					[messages]="{
          emptyMessage: 'labels.empty-lists' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<a
								routerLink="/list/{{row.id}}"
								class="link"
								ngbTooltip="{{'labels.details' | translate}}">
								{{value}}
							</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="slug"
						[flexGrow]="1"
						[name]="'labels.slug' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="valuetype"
						[flexGrow]="1"
						[name]="'labels.valuetype' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="descr"
						[flexGrow]="2"
						[name]="'labels.descr' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">

								<a
									[routerLink]="row.id"
									class="link">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>
			</div>
		</app-card>
	</div>
</div>
