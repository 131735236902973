import { Feature } from './feature.model';
import { Exclude, Transform } from 'class-transformer';
import { bool2Num, dateTransform, num2Bool } from 'elbuild-ui-lib-core';

export class Role {
	id: number;
	rolename: string;
	description: string;
	@Transform(bool2Num, { toPlainOnly: true })
	@Transform(num2Bool, { toClassOnly: true })
	sudo: boolean = false;

	@Transform(bool2Num, { toPlainOnly: true })
	@Transform(num2Bool, { toClassOnly: true })
	editable: boolean = true;

	@Exclude({ toPlainOnly: true })
	slug: string;

	// "transient" fields
	@Exclude()
	features: RoleFeature[];
	@Exclude()
	showdetails: boolean;

	@Transform(dateTransform)
	insdate: number;

	@Transform(dateTransform)
	moddate: number;
}

export class RoleFeature {
	id: number;
	rid: number;
	fid: number;
	feature: Feature;
}

export class UserToRole {
	id: number;
	rid: number;
	uid: number;
	@Transform(dateTransform)
	insdate: number;
}

export enum RoleSlug {
	ADMIN = 'admin',
	SUPER_ADMIN = 'superadmin',
}

export function role() {
	return Role;
}
