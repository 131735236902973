<app-navigation
	class="pcoded-navbar"
	[ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.gradientConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
	(onNavMobCollapse)="navMobClick()"></app-navigation>
<app-nav-bar
	class="navbar pcoded-header navbar-expand-lg navbar-light"
	(onNavCollapse)="this.navCollapsed = !this.navCollapsed;"
	(onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
	<div
		class="pcoded-wrapper"
		[ngClass]="{'container': this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2'}">
		<div class="pcoded-content">
			<div class="pcoded-inner-content">
				<div class="main-body">
					<div class="page-wrapper">
						<app-breadcrumb></app-breadcrumb>
						<router-outlet></router-outlet>
						<div class="footer">
							<div class="copyright text-center">
								Copyright  ©  {{currentDate | date: 'yyyy'}}
								<a
									id="pixinventLink"
									target="blank"
									href="https://www.elbuild.it/">
									EL Admin
								</a>
								, All rights reserved.
								<div class="pull-right">
									<strong class="version">
										UI v. {{version.version}} {{env}} ({{version.hash}}
										<span *ngIf="version.tag">tag {{version.tag}}</span>
										)
									</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-configuration></app-configuration>
