<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && save()">
	<div class="modal-header">
		<h4 class="modal-title">{{'labels.new-event' | translate}}</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>
		<div class="row">
			<div class="col text-center">
				<app-loader
					*ngIf="loading"
					[material]="false"
					[dark]="true"></app-loader>
			</div>
		</div>

		<div
			class="row"
			*ngIf="event">
			<div class="col-sm-12">
				<div class="row">
					<!-- taskid -->
					<div
						*ngIf="!taskid"
						class="form-group col-md-4">
						<label for="task">{{ 'labels.task' | translate }}*</label>
						<app-entity-autocomplete
							#task="ngModel"
							(onSelected)="onTaskSelected($event);"
							(onDeleted)="onTaskSelected($event)"
							(ngModelChange)="refresh.next()"
							entityName="task"
							entityField="name"
							[ngClass]="{'is-invalid': editForm.submitted && task.invalid}"
							[required]="true"
							entityFilterType="like"
							[entitySearch]="search.task"
							[entityClass]="taskClass"
							[name]="'task'"
							name="task"
							[(ngModel)]="event.task"
							placeholder
							[loadonfocus]="true">
						</app-entity-autocomplete>

					</div>
					<!-- userid -->
					<div
						*ngIf="!userid"
						class="form-group col-md-4">
						<label for="user">{{ 'labels.user' | translate }}*</label>
						<app-entity-autocomplete
							#user="ngModel"
							(onSelected)="onUserSelected($event);"
							(onDeleted)="onUserSelected($event)"
							(ngModelChange)="refresh.next()"
							entityName="user"
							entityField="concat(fname, ' ', lname)"
							entityFilterType="like"
							[entityFieldFormatter]="userFormatter"
							[entitySearch]="search.user"
							[required]="true"
							[entityClass]="userClass"
							[name]="'user'"
							[(ngModel)]="event.user"
							[ngClass]="{'is-invalid': editForm.submitted && user.invalid}"
							placeholder
							[loadonfocus]="true">
						</app-entity-autocomplete>

					</div>
					<div class="form-group col-md-4">
						<label for="type">{{ 'labels.type' | translate }}*</label>
						<select
							#type="ngModel"
							class="form-control"
							[required]="true"
							[ngClass]="{'is-invalid': editForm.submitted && type.invalid}"
							[(ngModel)]="event.type"
							[name]="'type'">
							<option [value]="undefined"></option>
							<option
								*ngFor="let item of usertasklogTypes"
								[value]="item.itemvalue">
								{{item.itemlabel}}
							</option>
						</select>

					</div>
					<div class="form-group col-md-6">
						<label for="start">{{ 'labels.from' | translate }}*</label>
						<app-datepicker
							#startdate="ngModel"
							[showtime]="true"
							[required]="true"
							[name]="'start'"
							[(ngModel)]="event.start"
							[ngClass]="{'is-invalid': editForm.submitted && startdate.invalid}"
							(onChange)="refresh.next()">
						</app-datepicker>
					</div>
					<div class="form-group col-md-6">
						<label for="end">{{ 'labels.to' | translate }}*</label>
						<app-datepicker
							#enddate="ngModel"
							[showtime]="true"
							[required]="true"
							[name]="'end'"
							[ngClass]="{'is-invalid': editForm.submitted && enddate.invalid}"
							[(ngModel)]="event.end"
							(onChange)="refresh.next()">
						</app-datepicker>
					</div>

					<div class="form-group col-md-12">
						<label for="note">{{ 'labels.note' | translate }}</label>
						<textarea
							[(ngModel)]="event.note"
							#note="ngModel"
							name="note"
							class="form-control"
							id="note"
							rows="3"
							placeholder="{{ 'labels.note' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && note.invalid}"></textarea>
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && note.invalid">
							{{'errors.invalid' | translate}}
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
		<button
			type="button"
			class="btn btn-secondary"
			(click)="dismiss()">
			{{'labels.cancel' | translate}}
		</button>
	</div>

</form>
