import { Injectable } from '@angular/core';
import { Icons } from 'matteini-ui-lib';

export interface NavigationItem {
	id: string;
	title: string;
	type: 'item' | 'collapse' | 'group';
	translate?: string;
	icon?: string;
	hidden?: boolean;
	url?: string;
	classes?: string;
	exactMatch?: boolean;
	external?: boolean;
	target?: boolean;
	breadcrumbs?: boolean;
	function?: any;
	badge?: {
		title?: string;
		type?: string;
	};
	children?: Navigation[];
	types?: string[];
	fontawsome: boolean;
	section?: string;
}

export interface Navigation extends NavigationItem {
	children?: NavigationItem[];
}

const NavigationItems = [

	{
		id: 'matteini',
		title: 'matteini',
		type: 'group',
		icon: 'feather icon-align-left',
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				type: 'collapse',
				icon: 'fa fa-chart-line',
				children: [
					{
						id: 'expense',
						title: 'Spese',
						type: 'item',
						url: '/dashboard/expense',
						classes: 'nav-item',
						icon: 'fa fa-euro-sign',
					},
				]
			},

			/*,
			{
				id: 'menu-level',
				title: 'Menu Levels',
				type: 'collapse',
				icon: 'feather icon-menu',
				children: [
					{
						id: 'menu-level-2.1',
						title: 'Menu Level 2.1',
						type: 'item',
						url: 'javascript:',
						external: true
					},
					{
						id: 'menu-level-2.2',
						title: 'Menu Level 2.2',
						type: 'collapse',
						children: [
							{
								id: 'menu-level-2.2.1',
								title: 'Menu Level 2.2.1',
								type: 'item',
								url: 'javascript:',
								external: true
							},
							{
								id: 'menu-level-2.2.2',
								title: 'Menu Level 2.2.2',
								type: 'item',
								url: 'javascript:',
								external: true
							}
						]
					}
				]
			},
			{
				id: 'disabled-menu',
				title: 'Disabled Menu',
				type: 'item',
				url: 'javascript:',
				classes: 'nav-item disabled',
				icon: 'feather icon-power',
				external: true
			}*/
		]
	},
	{
		id: 'Anagrafica',
		title: 'Anagrafica',
		type: 'collapse',
		icon: 'feather icon-align-left',
		section: 'section.customer',
		children: [
			{
				id: 'tutti',
				title: 'Tutti',
				type: 'item',
				url: '/customer/all',
				classes: 'nav-item',
				icon: 'feather icon-users',
			},
			{
				id: 'society',
				title: 'Società',
				type: 'item',
				url: '/customer/society',
				classes: 'nav-item',
				icon: 'feather icon-users',
			},
			{
				id: 'publicinstitution',
				title: 'Enti pubblici',
				type: 'item',
				url: '/customer/publicinstitution',
				classes: 'nav-item',
				icon: 'feather icon-users',
			},
			{
				id: 'creditinstitution',
				title: 'Istituti di credito',
				type: 'item',
				url: '/customer/creditinstitution',
				classes: 'nav-item',
				icon: 'feather icon-users',
			},
			{
				id: 'suppliers',
				title: 'Fornitori',
				type: 'item',
				url: '/customer/supplier',
				classes: 'nav-item',
				icon: 'feather icon-users',
			},
			{
				id: 'addressbook',
				title: 'Rubrica',
				type: 'item',
				url: '/customer/addressbook',
				classes: 'nav-item',
				icon: 'feather icon-users',
			},
		]


	},
	{
		id: 'project',
		title: 'Commesse',
		type: 'item',
		url: '/project',
		classes: 'nav-item',
		icon: 'fas fa-bezier-curve',
	},
	{
		id: 'general-expense',
		title: 'Spese generali',
		type: 'item',
		url: '/general-expense/expense',
		classes: 'nav-item',
		icon: 'fa fa-euro-sign',
	},
	{
		id: 'documents',
		title: 'Documenti',
		type: 'item',
		url: '/documents',
		classes: 'nav-item',
		icon: 'fa fa-paperclip',
	},
	{
		id: 'mail',
		title: 'Comunicazioni',
		type: 'item',
		url: '/mail',
		classes: 'nav-item',
		icon: Icons.MAIL,
		section: 'section.mail'
	},


	{
		id: 'admin',
		title: 'Admin',
		type: 'group',
		icon: 'feather icon-align-left',
		children: [
			{
				id: 'profile',
				title: 'Profilo',
				type: 'item',
				url: '/profile',
				classes: 'nav-item',
				icon: Icons.PROFILE,
			},
			{
				id: 'users',
				title: 'Utenti',
				type: 'item',
				url: '/user',
				classes: 'nav-item',
				icon: 'feather icon-users',
				section: 'section.users'
			},
			{
				id: 'rroles',
				title: 'Ruoli e Feature',
				type: 'item',
				url: '/rolefeature',
				classes: 'nav-item',
				icon: 'feather icon-file-text',
				section: 'section.rolefeature'
			},
			{
				id: 'list',
				title: 'Liste',
				type: 'item',
				url: '/list',
				classes: 'nav-item',
				icon: Icons.LISTS,
				section: 'section.lists'
			},
			{
				id: 'template',
				title: 'Template',
				type: 'item',
				url: '/template',
				classes: 'nav-item',
				icon: Icons.CLIPBOARD,
				section: 'section.template'
			},
			{
				id: 'advanced',
				title: 'Impostazioni',
				type: 'collapse',
				classes: 'nav-item',
				icon: 'fa fa-cog',
				children: [{
					id: 'advanced',
					title: 'Avanzate',
					type: 'item',
					url: '/advanced',
					classes: 'nav-item',
				},
				{
					id: 'configsettings',
					title: 'Configurazione',
					type: 'item',
					url: '/config',
					classes: 'nav-item',
				}

				]
			}
		],
	}

];

@Injectable()
export class NavigationItem {
	public get() {
		return NavigationItems;
	}
}
