import { registerLocaleData } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { environment } from 'src/environments/environment';
import { VersionCheckService } from './@core/data/version-check.service';

import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeItExtra from '@angular/common/locales/extra/it';
import localeIt from '@angular/common/locales/it';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'matteini-backoffice';
  constructor(private router: Router,
    private sharedDataService: SharedDataService,
    private versionCheckService: VersionCheckService) {
    // register locale for pipe
    registerLocaleData(localeIt, 'it', localeItExtra);
    registerLocaleData(localeEn, 'en', localeEnExtra);
  }

  ngOnInit() {
    if (environment.production) {
      this.versionCheckService.initVersionCheck('/version.json');
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
