import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class SettingsService {

	constructor(
		protected http: HttpClient,
	) {}

	resetCache(): Observable<any> {
		return this.http.get(`/ws/cache`)
			.pipe(map(res => res));
	}
}