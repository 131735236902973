import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NavigationItem } from '../../../layout/admin/navigation/navigation';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService, BreadcrumbItem, Breadcrumb } from 'elbuild-ui-lib-core';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mergeMap';
import { map, filter, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	@Input() type: string;
	@Input() badge: { style: string; text: string; };

	@Output() linkClicked = new EventEmitter();


	docinvalid: boolean;
	shared = true;
	_breadcrumb: Breadcrumb;


	@Input()
	set breadcrumb(s: Breadcrumb) {
		this._breadcrumb = s;
		if (s) {
			this.setTitle(s.title);
			this.navigationList = s.items;
			this.shared = false;
			if (s.data) {
				this.docinvalid = s.data.docinvalid;
			}
		}

	}




	@Input()
	public navigationList: Array<any> = [];

	pageInfo: any;
	section: string;

	data: any;
	breadcrumbSubscription: Subscription;
	titleSubscription: Subscription;
	routeSubcription: Subscription;
	docvalidsubscription: Subscription;
	title: BreadcrumbItem;


	constructor(private router: Router, public nav: NavigationItem, public titleService: Title,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private translateService: TranslateService,
		private sharedDataService: SharedDataService) {
		this.type = 'theme2';
		this.setBreadcrumb();
	}

	ngOnInit() {

		if (this.shared) {



			this.setSharedBreadcrumb();
		}
	}

	ngOnDestroy() {
		if (this.breadcrumbSubscription) {
			this.breadcrumbSubscription.unsubscribe();
		}
		if (this.titleSubscription) {
			this.titleSubscription.unsubscribe();
		}
		if (this.docvalidsubscription) {
			this.docvalidsubscription.unsubscribe();
		}
	}

	setSharedBreadcrumb() {
		this.breadcrumbSubscription = this.sharedDataService.currentBreadcrumb.subscribe((data: BreadcrumbItem[]) => {


		});
		this.titleSubscription = this.sharedDataService.currentTitle.subscribe((data: BreadcrumbItem) => {
			if (data) {
				this.setTitle(data);

			}
		});

	}

	setDocvalid() {
		this.docinvalid = undefined;

		this.sharedDataService.addCommonData('docinvalid', undefined);

		this.docvalidsubscription = this.sharedDataService.currentCommondata.subscribe((data: any) => {
			if (data && data.docinvalid !== undefined) {
				this.docinvalid = data.docinvalid;
				this.cdr.detectChanges();
			}
		});
	}

	setBreadcrumb() {
		this.router.events
			.filter(event => {
				const routerUrl = event['urlAfterRedirects'];
				if (routerUrl && typeof routerUrl === 'string') {
					const activeLink = event['url'];
					// 	this.filterNavigation(activeLink);
				}
				return event instanceof NavigationEnd;
			})
			.pipe(map(() => this.activatedRoute)).pipe(
				map(route => {
					while (route['firstChild']) {
						route = route['firstChild'];
					}
					return route;
				})).pipe(
					filter(route => route['outlet'] === 'primary'))
			.pipe(mergeMap(route => route['data']))
			.subscribe(data => {
				if (this.shared) {
					this.pageInfo = event;
					this.setDocvalid();
					if (data['title']) {
						this.setTitle(new BreadcrumbItem(data['title']));
					} else {
						this.setTitle(undefined);
					}
					if (data['urls']) {
						this.navigationList = data['urls'];
					} else {
						this.navigationList = [];
					}

				}
			});
	}

	onLinkClicked(breadcrumb: Breadcrumb) {
		this.linkClicked.emit(breadcrumb);
	}

	setTitle(val: BreadcrumbItem) {

		if (val && val.title) {
			this.title = new BreadcrumbItem(val.title, val.param, val.url, val.icon, val.icontooltip);
			this.translateService.get(this.title.title, this.title.param).subscribe(message => {
				this.titleService.setTitle(message);
			}, () => this.titleService.setTitle(event['title']));
		} else {
			this.titleService.setTitle('Matteini Backoffice');
			this.title = new BreadcrumbItem('');
		}
	}





}
