
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// tslint:disable-next-line:max-line-length
import { NgbCollapseModule, NgbDatepickerModule, NgbModalModule, NgbPaginationModule, NgbTabsetModule, NgbTimepickerModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService, ElbuildCoreComponentsModule } from 'elbuild-ui-componentslib';
import { BaseAuthModule, PipesModule } from 'elbuild-ui-lib-core';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ListItemEditComponent } from './list/list-item-edit/list-item-edit.component';
import { ListItemListComponent } from './list/list-item-list/list-item-list.component';
import { ListListComponent } from './list/list-list/list-list.component';
import { UserNewEditComponent } from './user-new-edit/user-new-edit.component';
import { UserTaskCalendarComponent } from './user-task-calendar/user-task-calendar.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule } from 'angular-calendar';
import { UserTaskCalendarModalComponent } from './user-task-calendar/user-task-calendar-modal/user-task-calendar-modal.component';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgbTooltipModule,
		NgbPaginationModule,
		NgbTypeaheadModule,
		NgbDatepickerModule,
		NgbTimepickerModule,
		RouterModule,
		PipesModule,
		BaseAuthModule,
		ElbuildCoreComponentsModule,
		SharedModule,
		NgxDatatableModule,
		TranslateModule.forChild(),
		NgbTooltipModule,
		NgbCollapseModule,
		NgbTabsetModule,
		NgbModalModule,
		FlatpickrModule.forRoot(),
		CalendarModule
	],
	exports: [
		ListListComponent,
		ListItemListComponent,
		ListItemEditComponent,
		UserNewEditComponent,
		UserTaskCalendarComponent
	],
	declarations: [
		ListListComponent,
		ListItemListComponent,
		ListItemEditComponent,
		UserNewEditComponent,
		UserTaskCalendarComponent,
		UserTaskCalendarModalComponent
	],

	providers: [DialogService],
	entryComponents: [
	],
})
export class CoreComponentsModule {
}
