import { UserAdmin } from 'matteini-ui-lib';
import { Component, DoCheck, OnInit, OnDestroy } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { GradientConfig } from '../../../../../app-config';
import { SharedDataService, Staff } from 'elbuild-ui-lib-core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-nav-right',
	templateUrl: './nav-right.component.html',
	styleUrls: ['./nav-right.component.scss'],
	providers: [NgbDropdownConfig],
	animations: [
		trigger('slideInOutLeft', [
			transition(':enter', [
				style({ transform: 'translateX(100%)' }),
				animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
			]),
			transition(':leave', [
				animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
			])
		]),
		trigger('slideInOutRight', [
			transition(':enter', [
				style({ transform: 'translateX(-100%)' }),
				animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
			]),
			transition(':leave', [
				animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
			])
		])
	]
})
export class NavRightComponent implements OnInit, OnDestroy, DoCheck {
	public visibleUserList: boolean;
	public chatMessage: boolean;
	public friendId: boolean;
	public gradientConfig: any;
	usersubsription: Subscription;
	loggeduser: UserAdmin;

	constructor(private sharedDataService: SharedDataService) {
		this.visibleUserList = false;
		this.chatMessage = false;
		this.gradientConfig = GradientConfig.config;
	}

	ngOnInit() {
		this.usersubsription = this.sharedDataService.currentLoggedUser.subscribe(data => {
			this.loggeduser = data as UserAdmin

		});
	}


	ngOnDestroy() {
		if (this.usersubsription) {
			this.usersubsription.unsubscribe();
		}
	}

	onChatToggle(friendID) {
		this.friendId = friendID;
		this.chatMessage = !this.chatMessage;
	}

	ngDoCheck() {
		if (document.querySelector('body').classList.contains('elite-rtl')) {
			this.gradientConfig['rtl-layout'] = true;
		} else {
			this.gradientConfig['rtl-layout'] = false;
		}
	}
}
