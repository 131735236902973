import { UserAdmin } from 'matteini-ui-lib';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavigationItem } from '../../navigation';
import { Location } from '@angular/common';
import { GradientConfig } from '../../../../../../app-config';
import { SharedDataService } from 'elbuild-ui-lib-core';

@Component({
	selector: 'app-nav-group',
	templateUrl: './nav-group.component.html',
	styleUrls: ['./nav-group.component.scss']
})
export class NavGroupComponent implements OnInit {
	@Input() item: NavigationItem;
	@Input() layout1: boolean = false;
	@Input() activeId: any;
	public gradientConfig: any;
	loggedUser: UserAdmin;

	constructor(private zone: NgZone, private location: Location, private sharedDataService: SharedDataService) {
		this.gradientConfig = GradientConfig.config;
		this.sharedDataService.currentLoggedUser.subscribe(loggedUser => {
			this.loggedUser = loggedUser as unknown as UserAdmin
		});

	}

	ngOnInit() {
		// at reload time active and trigger link
		let current_url = this.location.path();
		if (this.location['_baseHref']) {
			current_url = this.location['_baseHref'] + this.location.path();
		}
		const link = 'a.nav-link[ href=\'' + current_url + '\' ]';
		const ele = document.querySelector(link);
		if (ele !== null && ele !== undefined) {
			const parent = ele.parentElement;
			const up_parent = parent.parentElement.parentElement;
			const last_parent = up_parent.parentElement;
			if (parent.classList.contains('pcoded-hasmenu')) {
				if (this.gradientConfig['layout'] === 'vertical') {
					parent.classList.add('pcoded-trigger');
				}
				parent.classList.add('active');
			} else if (up_parent.classList.contains('pcoded-hasmenu')) {
				if (this.gradientConfig['layout'] === 'vertical') {
					up_parent.classList.add('pcoded-trigger');
				}
				up_parent.classList.add('active');
			} else if (last_parent.classList.contains('pcoded-hasmenu')) {
				if (this.gradientConfig['layout'] === 'vertical') {
					last_parent.classList.add('pcoded-trigger');
				}
				last_parent.classList.add('active');
			}
		}
	}

}
