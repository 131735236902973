import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackofficeAuthRoutingModule } from './backoffice-auth-routing.module';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		LoginComponent,
		ChangePasswordComponent
	],
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		BackofficeAuthRoutingModule,
		FormsModule
	],
	providers: [
	]
})
export class BackofficeAuthModule { }
