import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, List, ListItem, MessageService, Search } from 'elbuild-ui-lib-core';
import { forkJoin } from 'rxjs';
import { ListItemEditComponent } from '../list-item-edit/list-item-edit.component';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';


@Component({
	selector: 'app-list-item-list',
	templateUrl: './list-item-list.component.html',
	styleUrls: ['./list-item-list.component.scss'],
})
export class ListItemListComponent implements OnInit {

	public rows: ListItem[];
	public list: List;
	public loading = false;
	public advancedSearch: Search = new Search('selistitem');

	get editable(): number {
		return this.list ? this.list.editable : 0;
	}

	constructor(
		private route: ActivatedRoute,
		private listitemService: CrudService<ListItem>,
		private listService: CrudService<List>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private modalService: NgbModal,
		private dialogService: DialogService,
		@Inject(LOCAL_STORAGE) private storage: StorageService,
	) {}

	public ngOnInit() {
		this.route.params.subscribe((params) => {
			const id = +params.id;
			this.loadList(id);
			this.loadItems(id);
		});
	}

	public loadList(id: number): void {
		this.listService.getEntity(List, 'selist', id).subscribe((l: List) => this.list = l);
	}

	public loadItems(id: number): void {
		this.advancedSearch.addSimpleFilter('lid', id);
		this.loading = true;
		this.rows = [];

		const datarequest = this.listitemService.searchEntities(ListItem, 'selistitem', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.listitemService.countEntities(ListItem, 'selistitem', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe((results) => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});

	}

	public edit(id?: number): void {
		const modal = this.modalService.open(ListItemEditComponent, {
			centered: true,
			size: 'lg',
			backdrop: 'static',
			keyboard: false,
		});
		modal.componentInstance.lid = this.list.id;
		if (id) {
			modal.componentInstance.load(id);
		} else {
			modal.componentInstance.load();
		}

		modal.result.then((newItem: ListItem) => {
			this.storage.remove('list-' + this.list.slug);
			this.loadItems(this.list.id);
		}).catch(() => {});
	}

	public delete(item: ListItem): void {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-listitem-delete'),
			hasIcon: true,
			type: 'warning',
		}).then((result) => {
			if (result) {
				this.listitemService.deleteEntity(ListItem, 'selistitem', item).subscribe(() => {
					this.translateService.get(['labels.item-deleted', 'labels.lists']).subscribe((messages) => {
						this.messageService.sendSuccess(messages['labels.item-deleted'], messages['labels.lists']);
						this.loadItems(this.list.id);
					});
				});
			}
		});
	}

	public onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.loadItems(this.list.id);
	}

	public onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.loadItems(this.list.id);
	}

	public rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

}
