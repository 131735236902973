import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Search, CrudService, MessageService, SharedDataService } from 'elbuild-ui-lib-core';
import { Country, Comune, Provincia, UserAdmin } from 'matteini-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassType } from 'class-transformer/ClassTransformer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Md5 } from 'ts-md5';

@Component({
	selector: 'app-user-new-edit',
	templateUrl: './user-new-edit.component.html',
	styleUrls: ['./user-new-edit.component.scss']
})
export class UserNewEditComponent implements OnInit, OnDestroy {


	@Input() user: UserAdmin;
	@Output() itemSaved: EventEmitter<UserAdmin> = new EventEmitter();
	loading: boolean = false;
	tab: string;
	title: string;
	editable: boolean = true;
	@Input() showcancel: boolean = true;

	search: {
		nazione: Search,
		comune: Search,
		provincia: Search
	};
	selected: {
		nazione: Country,
		comune: Comune,
		provincia: Provincia,
	};
	nazioneClass: ClassType<Country> = Country;

	password: {
		password: string,
		repeatpassword: string
	};



	constructor(private crudService: CrudService<UserAdmin>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private sharedDataService: SharedDataService,
		private route: ActivatedRoute,
		private countryService: CrudService<Country>,
		private provinceService: CrudService<Provincia>,
		private comuneService: CrudService<Comune>,

		private router: Router
	) {

		this.selected = {
			nazione: new Country(),
			comune: undefined,
			provincia: undefined,
		};
		this.search = {
			nazione: new Search('country'),
			comune: new Search('comuni'),
			provincia: new Search('province')

		};
		this.password = { password: '', repeatpassword: '' };
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = +params['id'];
			if (id) {
				this.load(id);
			} else if (this.user) {
				this.loading = true;
				this.selected.nazione = new Country(this.user?.country);
				this.selected.provincia = new Provincia(this.user?.province);
				this.selected.comune = new Comune(this.user?.comune);
				this.loading = false
			} else {
				this.title = 'user-create';
				this.user = new UserAdmin();

			}

		});


	}

	ngOnDestroy() {
		this.sharedDataService.addCommonData('route', undefined);
	}

	load(id: number) {
		this.loading = true;
		this.crudService.getEntity(UserAdmin, 'user', id).subscribe(user => {
			this.user = user;
			this.title = 'user-edit';
			this.loading = false;
			this.initItem();
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.user'));
			this.user = undefined;
			this.loading = false;
		});
	}


	findCountry(name: any): void {
		if (name) {
			const countrySearch = new Search('country');
			countrySearch.addLikeFilter('name', name);
			this.countryService
				.searchEntities(Country, 'country', countrySearch, countrySearch.page)
				.subscribe((c: Country[]) => {
					this.selected.nazione = c[0];
				});
		}
	}

	findProvince(descrizione: any): void {
		if (descrizione) {
			const provinceSearch = new Search('province');
			provinceSearch.addLikeFilter('descrizione', descrizione);
			this.provinceService
				.searchEntities(Provincia, 'province', provinceSearch, provinceSearch.page)
				.subscribe((p: Provincia[]) => {
					this.selected.provincia = p[0];
				});
		}
	}

	findComune(descrizione: any): void {
		if (descrizione) {
			const comuneSearch = new Search('comuni');
			comuneSearch.addLikeFilter('descrizione', descrizione);
			this.comuneService
				.searchEntities(Comune, 'comuni', comuneSearch, comuneSearch.page)
				.subscribe((c: Comune[]) => {
					this.selected.comune = c[0];
				});
		}
	}

	updateSelects() {

		if (this.selected.provincia) {
			this.search.comune.addSimpleFilter('provincia', this.selected.provincia.descrizione);
		} else {
			this.selected.comune = undefined;
		}
		if (this.selected.nazione === undefined || this.selected.nazione.name !== 'Italia') {
			this.selected.comune = undefined;
			this.selected.provincia = undefined;
		}
	}

	setSelectFields() {
		if (this.selected.nazione && this.selected.nazione.id) {
			this.user.country = this.selected.nazione.name;
			this.user.iso = this.selected.nazione.sigla;
		}
		if (this.selected.comune && this.selected.comune.id) {
			this.user.comune = this.selected.comune.descrizione;
		}
		if (this.selected.provincia && this.selected.provincia.id) {
			this.user.province = this.selected.provincia.descrizione;
		}
	}

	initItem() {
		this.findCountry(this.user.country);
		this.findProvince(this.user.province);
		this.findComune(this.user.comune);
	}


	submit() {
		if (this.password && this.password.password && this.password.password !== '') {
			if (this.password.password !== this.password.repeatpassword) {
				this.messageService.sendError(this.translateService.instant('labels.password-mismatch'), this.translateService.instant('labels.create-user'));
				return;
			} else {
				this.user.password = Md5.hashStr(this.password.password) as string;
			}
		} else {
			this.user.password = undefined;
		}
		this.setSelectFields();
		this.itemSaved.emit(this.user);
	}



	cancel() {
		if (this.user.id) {
			this.itemSaved.emit(null);
		} else {
			this.router.navigateByUrl('/user');
		}

	}
}
