import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from 'elbuild-ui-lib-core';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';

const routes: Routes = [
	{
		path: '',
		component: AdminComponent,
		children: [
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full',
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./content/pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
				data: {
					types: ['admin'],
					section: 'dashboard',
					aclFeatures: ['section.dashboard']

				},
			},

			{
				path: 'user',
				loadChildren: () => import('./content/pages/user/user.module').then((module) => module.UserModule),
				data: {
					section: 'users',
					aclFeatures: ['section.users']
				}
			},
			{
				path: 'list',
				loadChildren: () => import('./content/pages/list/list.module').then((module) => module.ListModule),
				data: {
					types: ['admin'],
					section: 'lists',
					aclFeatures: ['section.lists'],
				},
			},
			{
				path: 'rolefeature',
				loadChildren: () => import('./content/pages/role-features/role-features.module').then((module) => module.RoleFeaturesModule),
				data: {
					types: ['admin'],
					section: 'rolefeature',
					aclFeatures: ['section.rolefeature'],
				},


			},
			{
				path: 'customer',
				loadChildren: () => import('./content/pages/customer/customer.module').then((module) => module.CustomerModule),
				data: {
					types: ['admin'],
					section: 'customer',
					aclFeatures: ['section.customer']

				},

			},
			{
				path: 'project',
				loadChildren: () => import('./content/pages/project/project.module').then(module => module.ProjectModule),
			},
			{
				path: 'task',
				loadChildren: () => import('./content/pages/task/task.module').then(module => module.TaskModule),
			},
			{
				path: 'general-expense',
				loadChildren: () => import('./content/pages/general-expense/general-expense.module').then(module => module.GeneralExpenseModule),
			},
			{
				path: 'documents',
				loadChildren: () => import('./content/pages/document/document.module').then(module => module.DocumentModule),
			},
			{
				path: 'advanced',
				loadChildren: () => import('./content/pages/advanced/advanced.module').then(module => module.AdvancedModule),
				data: {
					types: ['admin'],
					section: 'advanced',
					aclFeatures: ['section.advanced']

				},
			},
			{
				path: 'config',
				loadChildren: () => import('./content/pages/config/config.module').then(module => module.ConfigModule),
				data: {
					types: ['admin'],
					section: 'advanced',
					aclFeatures: ['section.advanced']

				},
			},

			{
				path: 'profile',
				loadChildren: () => import('./content/pages/profile/profile.module').then(module => module.ProfileModule),
				data: {
					title: 'labels.profile'
				}
			},

			{
				path: 'template',
				loadChildren: () => import('./content/pages/template/template.module').then(module => module.TemplateModule),
				data: {
					types: ['admin'],
					section: 'template',
					aclFeatures: ['section.template']
				}
			},
			{
				path: 'mail',
				loadChildren: () => import('./content/pages/mail/mail.module').then(module => module.MailModule),
				data: {
					types: ['admin'],
					section: 'mail',
					aclFeatures: ['section.mail']
				}
			},
			{
				path: 'unauthorized',
				loadChildren: () => import('./content/pages/unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
			},
			{ path: 'login', redirectTo: '/signin', pathMatch: 'full' },
		],
		canActivate: [AuthenticationGuardService],
		canActivateChild: [AuthenticationGuardService],
	},
	{
		path: '',
		component: AuthComponent,
		children: [],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
